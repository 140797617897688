<template>
  <main class="bg-lightgrey">
    <PageHeader title="Board Resources" />

    <PageContent>
      <template #default>
        <ModalFileLibrary :is-board-resources-context="true" />
        <hr class="mt-6 mb-8" />
        <div ref="parentRef" class="inline-block pl-4">
          <FilesList
            v-if="hasFiles"
            :files="files"
            :is-board-resources-context="true"
          />
          <div v-else>
            <span class="text-gray-400 text-sm">No board resources</span>
          </div>
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
import ModalFileLibrary from '../../components/modal/ModalFileLibrary.vue'
import FilesList from '../../components/FilesList.vue'

export default defineComponent({
  components: { PageHeader, PageContent, ModalFileLibrary, FilesList },

  setup() {
    const store = useStore()
    const [parentRef] = useAutoAnimate()
    const files = computed(
      () => store.getters['portal/getLibraryResourceFiles'],
    )
    const hasFiles = computed(() => files.value?.length)

    return {
      parentRef,
      files,
      hasFiles,
    }
  },
})
</script>
