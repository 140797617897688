<template>
  <div class="h-screen flex bg-white text-ces-blue">
    <div class="flex min-h-full flex-1">
      <div
        class="w-full flex flex-1 flex-col items-center sm:items-start sm:justify-center px-8 py-12 sm:px-12 lg:flex-none lg:px-20 xl:px-24 space-y-8"
      >
        <svg-logo
          class="flex mx-auto h-42 sm:hidden"
          size="160"
          :is-vertical="true"
        ></svg-logo>

        <div class="w-full z-10 relative space-y-4 max-w-sm">
          <div class="flex flex-col items-center sm:items-start mb-12">
            <h1 class="font-bold text-3xl mb-1">Welcome to the CES App</h1>
            <span class="italic">Which type of user are you?</span>
          </div>
          <div class="flex items-center justify-center flex-col space-y-3">
            <a
              class="w-full text-center p-4 rounded bg-ces-teal border border-ces-teal text-white hover:bg-white hover:text-ces-teal hover:p-6 cursor-pointer transition-all"
              href="/admin"
              >I'm a CES Admin</a
            >
            <div class="w-1/4 h-px bg-truegray-300"></div>
            <router-link
              :to="{ name: 'signIn' }"
              class="w-full text-center p-4 rounded bg-ces-teal border border-ces-teal text-white hover:bg-white hover:text-ces-teal hover:p-6 cursor-pointer transition-all"
            >
              I'm a CES Client
            </router-link>
          </div>
        </div>
        <!-- Show the enquiry form -->
      </div>
    </div>
    <div class="relative hidden w-0 flex-1 sm:block bg-ces-blue">
      <svg-logo
        class="text-white flex mx-auto h-full"
        size="160"
        :is-vertical="true"
      ></svg-logo>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { ANIMATION_LOADING } from '../../constants.js'

export default defineComponent({
  setup() {
    return {
      ANIMATION_LOADING,
    }
  },
})
</script>
