export const triggerAlert = (context, response) => {
  if (response?.data?.alert) {
    context.dispatch('global/dispatchUpdateAlertToast', response.data.alert, {
      root: true,
    })
  }
}

export const mapUser = (user) => {
  return {
    id: user.id,
    name: user.name,
    boardRole: user.board_role,
    isFileLord: user.is_portal_file_lord,
    role: user.role,
    isAdmin: user.role < 3,
    email: user.email,
    mobile: user.mobile,
    phone: user.tel,
    deletedAt: user.deleted_at
  }
}

export const mapReport = (report) => {
  return {
    ...report,
    date: new Date(report.created_at).toLocaleDateString(),
  }
}

// "No members", "No attendees", "No apologies", etc.
export const pluraliseNoun = (noun, count) => {
  if (count !== 1) {
    if (noun.endsWith('y')) {
      // Remove the 'y' and add 'ies'
      return `${noun.slice(0, -1)}ies`
    } else {
      return `${noun}s`
    }
  }

  return noun
}

/************** Meetings/attendees helpers **************/
export const getAttendeesText = (members, title) => {
  const length = members.length
  const key = length || 'No'
  return `${key} ${pluraliseNoun(title, length)}`
}

// @TODO Portal 2.0 - map other user fields if we want them displayed
// also consider saving boardRole as board_role in json to avoid this extra || map
export const mapAttendee = (user) => {
  return {
    value: user.id,
    title: user.name,
    boardRole: user.board_role || user.boardRole,
    attending: user.attending,
    manual: !!user.manual,
    // Email is important for manual attendees - it's the primary key!
    email: user.email,
    deletedAt: user.deleted_at
  }
}

/**
 * mapMeetingWithAttendees
 *
 * Maps meeting manual and board member attendees into one users list and returns
 * updated meeting object.
 *
 * @param {Object} meeting - The meeting object containing user data.
 * @param {Array} meeting.users - Array of board member attendees, with attending status.
 * @param {string} [meeting.manual_attendees] - JSON string of manual attendees which need mapping.
 * @returns {Object} - The meeting object with mapped attendees.
 */
export const mapMeetingWithAttendees = (meeting) => {
  const meetingAttendees = [...meeting.users]

  const meetingAttendeesManual = meeting.manual_attendees
    ? Object.values(JSON.parse(meeting.manual_attendees)).map((user) => ({
        // If manual attendees are stored, they ARE attending!
        // And they need a manual flag.
        ...user,
        attending: true,
        manual: true,
      }))
    : []

  return {
    ...meeting,
    users: [...meetingAttendees, ...meetingAttendeesManual].map(mapAttendee),
  }
}

export const formatDate = (dateString) => {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}