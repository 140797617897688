<template>
  <div class="w-full">
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
      <div class="px-4 sm:px-0">
        <h2 class="text-base font-Inter font-bold">General Settings</h2>
        <p class="mt-1 text-xs text-gray-600">
          Update your school's website and branding
        </p>
      </div>
      <div
        class="bg-white shadow-sm sm:rounded-xl md:col-span-2 px-4 py-6 sm:p-8"
      >
        <FormKit id="form" v-model="form" type="form" @submit="onSubmit">
          <FormKit
            type="url"
            name="website"
            label="School Website URL"
            placeholder="https://www.example.com..."
            help="What is your school's website?"
            validation="url"
          />

          <div class="flex items-center w-full">
            <FormKit
              type="file"
              label="Logo"
              name="logo"
              help="Upload your school logo"
              accept=".jpg,.png"
            />
            <SchoolLogo wrapper-class="my-4 mx-8" />
          </div>
        </FormKit>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { reset } from '@formkit/core'
import SchoolLogo from '../../components/SchoolLogo.vue'

export default defineComponent({
  components: {
    SchoolLogo,
  },

  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const isLoading = computed(() => store.getters['global/isLoading'])

    const route = useRoute()

    const settings = computed(() => store.getters['portal/getSettings'])

    const form = ref({
      logo: null,
      website: settings.value?.client_website_url,
    })

    const canSubmit = computed(() => {
      return form.value.logo.length || form.value.website
    })

    const onSubmit = async (data) => {
      if (canSubmit.value) {
        const formData = new FormData()
        if (data.logo?.length) {
          formData.append('logo', data.logo[0].file || null)
        }
        formData.append('website', data.website || '')

        await store.dispatch('portal/dispatchUpdateGeneralSettings', formData)
        reset('form')
      }
    }

    return {
      isLoading,
      isAdmin,
      route,
      form,
      onSubmit,
      canSubmit,
      settings,
    }
  },
})
</script>
