<template>
  <div class="h-screen flex bg-white text-ces-blue">
    <div class="flex min-h-full flex-1">
      <div
        class="w-full flex flex-1 flex-col items-center sm:items-start sm:justify-center px-8 py-12 sm:px-12 lg:flex-none lg:px-20 xl:px-24 space-y-8"
      >
        <svg-logo
          class="flex mx-auto h-42 sm:hidden"
          size="160"
          :is-vertical="true"
        ></svg-logo>

        <!-- Show the enquiry form -->
        <div
          v-if="hasResetPassword"
          class="w-full z-10 relative space-y-4 max-w-xs"
        >
          <div class="flex flex-col items-center sm:items-start mb-12">
            <h1 class="font-bold text-3xl mb-2">Thank you!</h1>
            <p class="font-Inter">
              Instructions to reset your password have been sent to {{ email }}.
            </p>
            <button
              type="button"
              class="flex w-full justify-center rounded-full shadow-sm px-6 py-2.5 bg-ces-teal text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm"
              @click="resetSignIn"
            >
              Sign in
            </button>
          </div>
        </div>

        <div v-else class="w-full z-10 relative space-y-4 max-w-xs">
          <div class="flex flex-col items-center sm:items-start mb-12">
            <h1 class="font-bold text-3xl mb-1">Welcome back!</h1>
          </div>
          <div>
            <label for="email" class="block text-sm font-medium sm:mt-px">
              Email address
            </label>
            <div class="mt-2">
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                class="block w-full rounded-md border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-teal sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div class="flex justify-between">
              <label for="password" class="block text-sm font-medium sm:mt-px">
                Password
              </label>
              <div
                :class="[
                  canResetPassword
                    ? 'cursor-pointer hover:text-ces-teal'
                    : 'opacity-50 cursor-not-allowed',
                  isResettingPassword ? 'text-ces-teal h-4 w-8' : '',
                ]"
                class="text-sm font-bold"
                @click="resetPassword"
              >
                <span v-if="isResettingPassword" class="block">
                  <Vue3Lottie :animation-data="ANIMATION_LOADING.DARK" />
                </span>
                <span v-else> Forgot password? </span>
              </div>
            </div>
            <div class="mt-2">
              <input
                id="password"
                v-model="password"
                name="password"
                type="password"
                class="block w-full rounded-md border-0 py-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ces-teal sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div v-if="validationHint" class="py-2">
            <p class="text-red-600 text-xs">
              {{ validationHint }}
            </p>
          </div>
          <div class="flex space-x-2">
            <button
              :class="[
                canSubmit
                  ? 'hover:bg-ces-blue'
                  : 'opacity-50 cursor-not-allowed',
                isSubmitting ? 'py-0' : 'py-0.5',
              ]"
              type="button"
              class="flex w-full justify-center rounded-full shadow-sm px-6 py-2.5 mt-4 bg-ces-teal text-base font-medium text-white focus:outline-none sm:w-auto sm:text-sm"
              @click="submit"
            >
              <div v-if="isSubmitting" class="h-full w-8">
                <Vue3Lottie :animation-data="ANIMATION_LOADING.DEFAULT" />
              </div>
              <span v-else> Let's start! </span>
            </button>
          </div>
        </div>
        <!-- Show the enquiry form -->
      </div>
    </div>
    <div class="relative hidden w-0 flex-1 sm:block bg-ces-blue">
      <svg-logo
        class="text-white flex mx-auto h-full"
        size="160"
        :is-vertical="true"
      ></svg-logo>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING, GENERIC_ERROR_MESSAGE } from '../../constants.js'

export default defineComponent({
  components: {
    Vue3Lottie,
  },

  props: {
    // routed here with query (e.g., password reset for this email)
    emailQuery: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    // Form
    const password = ref(null)
    const email = ref(null)
    const validationHint = ref(null)
    const emailValid = computed(() => {
      const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      const valid = !!regex.test(email.value)
      return valid
    })
    const passwordValid = computed(() => {
      const valid = password.value
      return valid
    })

    const isSubmitting = ref(false)
    const isResettingPassword = ref(false)
    const canSubmit = computed(() => {
      return emailValid.value && passwordValid.value
    })
    const canResetPassword = computed(() => {
      return (
        !isResettingPassword.value && !isSubmitting.value && emailValid.value
      )
    })

    const hasResetPassword = ref(false)
    const resetSignIn = () => {
      router.push({ path: '/signin' })
      email.value = null
      password.value = null
      validationHint.value = null
      isSubmitting.value = false
      isResettingPassword.value = false
      hasResetPassword.value = false
    }

    const submit = async () => {
      if (!canSubmit.value) {
        return false
      }

      isSubmitting.value = true
      validationHint.value = null

      const response = await store.dispatch('portal/dispatchSignIn', {
        email: email.value,
        password: password.value,
      })

      isSubmitting.value = false

      if (response) {
        router.push({ path: '/portal' })
      } else {
        validationHint.value = response.alert?.message || GENERIC_ERROR_MESSAGE
      }
    }

    const resetPassword = async () => {
      const payload = { email: email.value }
      isResettingPassword.value = true
      const response = await store.dispatch(
        'auth/dispatchResetPassword',
        payload,
      )
      isResettingPassword.value = false

      if (response) {
        hasResetPassword.value = true
      }
    }

    const resetValidationHint = () => {
      validationHint.value = null
    }

    watch(email, resetValidationHint)
    watch(password, resetValidationHint)

    onMounted(() => {
      if (props.emailQuery) {
        email.value = props.emailQuery
      }
    })

    return {
      ANIMATION_LOADING,
      email,
      password,
      validationHint,
      hasResetPassword,
      canSubmit,
      canResetPassword,
      isSubmitting,
      isResettingPassword,
      submit,
      resetPassword,
      resetSignIn,
    }
  },
})
</script>
