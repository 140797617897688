<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :title="MODAL_CONFIG.title"
    :description="MODAL_CONFIG.description"
    :content-classes="MODAL_CONFIG.contentClasses"
    :is-dialog-open="isModalOpen"
    @update-modal-state="updateIsModalOpen"
  >
    <template #trigger>
      <DialogTrigger>
        <div class="flex items-center group">
          <div
            :class="[
              'group flex items-center cursor-pointer items-center gap-x-4 rounded-full p-2 text-sm text-ces-teal border-1 border-ces-teal group-hover:text-ces-blue transition-all',
              { 'group-hover:bg-white': !isBoardResourcesContext },
            ]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
              />
            </svg>
          </div>
          <div
            class="text-sm text-ces-teal group-hover:text-ces-blue transition-all ml-4"
          >
            {{ triggerText }}
          </div>
        </div>
      </DialogTrigger>
    </template>

    <template #content>
      <div class="pb-12 w-full bg-truegray-100 h-full overflow-y-scroll">
        <div
          class="flex items-center justify-between bg-white pt-12 px-12 pb-4"
        >
          <h2>File Library</h2>
        </div>

        <div class="pt-12 mx-12">
          <!-- Tabs -->
          <div
            v-show="!isBoardResourcesContext"
            class="flex bg-white w-[max-content] rounded-lg overflow-hidden mb-6"
          >
            <button
              v-for="tab in FILE_TABS"
              :key="tab.id"
              type="button"
              :title="tab.title"
              class="py-3 text-sm px-6 md:px-10 flex justify-center items-center transition-all duration-100 ease-in-out"
              :class="[
                isActiveFileTab(tab.id)
                  ? 'border border-ces-blue text-white bg-ces-blue rounded-lg'
                  : 'bg-white',
              ]"
              @click="() => selectFileTab(tab.id)"
            >
              <span>
                {{ tab.title }}
              </span>
            </button>
          </div>
          <!-- /Tabs -->

          <!-- Tab content -->
          <div
            ref="tabContentParent"
            class="flex gap-4 flex-wrap lg:flex-nowrap w-full"
          >
            <div
              ref="tabContentMainParent"
              class="bg-white rounded-lg p-8 h-[max-content]"
              :class="[selectedFiles.length ? 'w-2/3' : 'w-full']"
            >
              <!-- Search -->
              <div
                v-if="canSearch"
                :key="activeFileTab.id"
                class="flex items-center w-full pb-2 mb-6 border-b border-truegray-100"
              >
                <FormKit
                  v-model="search"
                  type="text"
                  :placeholder="`Search ${activeFileTab.title}`"
                  inner-class="shadow-none"
                  @actions="false"
                />
              </div>
              <!-- /Search -->

              <!-- Editing uploaded file -->
              <div
                v-if="isEditingUploadedFile"
                class="flex items-center space-x-8 mt-4 pb-6 border-b border-truegray-100"
              >
                <div class="flex flex-col space-y-2">
                  <FormKit
                    :key="editedUploadedFile.id"
                    v-model="editedUploadedFile.title"
                    type="text"
                    :label="`Edit file: ${editedUploadedFile.originalTitle}`"
                  />
                  <FormKit
                    v-if="!isBoardResourcesTab"
                    v-model="editedUploadedFile.publicExcluded"
                    type="checkbox"
                    decorator-icon="check"
                    label="Public excluded"
                    outer-class="$reset mb-0 pl-1"
                  />
                </div>

                <div class="flex space-x-4">
                  <button
                    type="button"
                    class="btn h-8 px-4 mb-0 border border-ces-blue"
                    @click="resetEditedUploadedFile"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn h-8 px-4 mb-0 bg-ces-teal text-white"
                    @click="onSubmitEditUploadedFile"
                  >
                    Save
                  </button>
                </div>
              </div>
              <!-- /Editing uploaded file -->

              <!-- Deleting uploaded file -->
              <div
                v-if="isDeletingUploadedFile"
                class="flex items-center space-x-8 mt-4 pb-6 border-b border-truegray-100"
              >
                <div>
                  Are you sure you want to delete
                  {{ deletedUploadedFile.title }}?
                </div>

                <div class="flex space-x-4">
                  <button
                    type="button"
                    class="btn h-8 px-4 mb-0 border border-ces-blue"
                    @click="resetDeleteUploadedFile"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn h-8 px-4 mb-0 bg-red-400 text-white"
                    @click="onSubmitDeleteUploadedFile"
                  >
                    Confirm
                  </button>
                </div>
              </div>
              <!-- /Deleting uploaded file -->

              <!-- Active tab: New Files -->
              <div v-if="isActiveFileTab('new')">
                <FormKit
                  id="uploadFilesForm"
                  ref="uploadFilesInputParent"
                  v-model="uploadFilesForm"
                  type="form"
                  :submit-label="uploadFilesSubmitLabel"
                  @submit="onSubmitUploadFiles"
                >
                  <FormKit
                    type="file"
                    :label="uploadFilesInputLabel"
                    name="filesToUpload"
                    multiple="true"
                    help="Use shift/cmd/ctrl while selecting to select multiple, or drag and drop"
                  />
                  <div
                    v-if="
                      !isUploadingFiles &&
                      uploadFilesForm?.filesToUpload?.length
                    "
                    ref="uploadFilesListParent"
                    class="flex flex-col divide-y divide-truegray-100 mb-8 pr-4 w-[max-content]"
                  >
                    <div
                      v-for="(file, index) in uploadFilesForm.filesToUpload"
                      :key="index"
                      class="flex justify-between items-center py-3"
                    >
                      <div class="flex space-x-4 items-center pr-12">
                        <div
                          title="Edit file name"
                          class="w-6 h-6 rounded-lg flex items-center justify-center cursor-pointer hover:bg-ces-blue hover:text-white text-ces-blue"
                          @click="() => editFile(index)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.013 2.513a1.75 1.75 0 0 1 2.475 2.474L6.226 12.25a2.751 2.751 0 0 1-.892.596l-2.047.848a.75.75 0 0 1-.98-.98l.848-2.047a2.75 2.75 0 0 1 .596-.892l7.262-7.261Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>

                        <p class="m-0">{{ file.name }}</p>
                      </div>

                      <FormKit
                        v-if="!isBoardResourcesContext"
                        v-model="
                          uploadFilesForm.filesToUpload[index].publicExcluded
                        "
                        type="checkbox"
                        decorator-icon="check"
                        label="Public excluded"
                        outer-class="$reset mb-0 pl-1"
                      />
                    </div>

                    <div v-if="isEditingFile" class="flex space-x-4 mt-8 pt-2">
                      <FormKit
                        v-model="editedFile.file.name"
                        type="text"
                        label="File name"
                      />
                      <button
                        type="button"
                        class="mt-2"
                        @click="onSubmitEditFile"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </FormKit>
              </div>
              <!-- /Active tab: New Files -->

              <!-- Active tab: NOT New Files -->
              <div
                v-else
                class="divide-y divide-truegray-100 max-h-[320px] overflow-y-scroll"
              >
                <!-- No files found -->
                <div v-if="!filteredFiles?.length" class="py-3">
                  <p class="text-truegray-500 italic mb-0">No files found...</p>
                </div>
                <!-- No files found -->

                <!-- Meetings tab content -->
                <template v-else-if="isActiveFileTab('meeting')">
                  <div
                    v-for="(meeting, index) in filteredFiles"
                    :key="index"
                    ref="meetingParent"
                    class="flex flex-col mb-4"
                    :class="{ 'pt-4': index !== 0 }"
                  >
                    <div class="flex">
                      <p class="font-bold text-ces-blue text-sm p-0 mb-1">
                        {{ meeting.title }}
                      </p>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        fill="currentColor"
                        class="w-4 h-4 ml-4 cursor-pointer"
                        @click="() => toggleMeetingTabSectionExpanded(index)"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm.75-10.25v2.5h2.5a.75.75 0 0 1 0 1.5h-2.5v2.5a.75.75 0 0 1-1.5 0v-2.5h-2.5a.75.75 0 0 1 0-1.5h2.5v-2.5a.75.75 0 0 1 1.5 0Z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p class="text-xs text-truegray-400 italic m-0">
                      {{ meeting.date }}
                    </p>
                    <div
                      :class="
                        meetingTabSectionsExpanded[index]
                          ? 'opacity-100'
                          : 'opacity-0 h-0 pointer-events-none'
                      "
                      class="ml-6"
                    >
                      <div
                        v-for="file in meeting.files"
                        :key="file.id"
                        class="flex items-center pb-1 pt-3"
                      >
                        <FormKit
                          :key="selectedFileIds.join(',')"
                          type="checkbox"
                          :value="selectedFileIds.includes(file.id)"
                          decorator-icon="check"
                          :label="file.title"
                          outer-class="$reset mb-0 grow-0"
                          :label-class="getFileLabelClass(file.id)"
                          @input="() => toggleSelectedFile(file.id)"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <!-- /Meetings tab content -->

                <!-- Tab content -->
                <template v-else>
                  <div
                    v-for="file in filteredFiles"
                    :key="file.id"
                    class="flex items-center justify-between group"
                  >
                    <div
                      :key="selectedFileIds.join(',')"
                      class="flex items-center py-3"
                    >
                      <FormKit
                        :key="file.id"
                        type="checkbox"
                        :value="selectedFileIds.includes(file.id)"
                        decorator-icon="check"
                        :label="file.title"
                        outer-class="$reset mb-0 grow-0"
                        :label-class="getFileLabelClass(file.id)"
                        :disabled="isModifyingUploadedFile"
                        @input="() => toggleSelectedFile(file.id)"
                      />
                    </div>

                    <div
                      v-show="!isModifyingUploadedFile"
                      class="flex space-x-2 opacity-0 group-hover:opacity-100 transition-all"
                    >
                      <div
                        title="Edit file"
                        class="rounded-xl text-black hover:text-white cursor-pointer w-8 h-8 bg-white hover:bg-ces-blue opacity-100 hover:opacity-60 flex items-center justify-center"
                        @click="() => editUploadedFile(file.id)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            d="m5.433 13.917 1.262-3.155A4 4 0 0 1 7.58 9.42l6.92-6.918a2.121 2.121 0 0 1 3 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 0 1-.65-.65Z"
                          />
                          <path
                            d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0 0 10 3H4.75A2.75 2.75 0 0 0 2 5.75v9.5A2.75 2.75 0 0 0 4.75 18h9.5A2.75 2.75 0 0 0 17 15.25V10a.75.75 0 0 0-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5Z"
                          />
                        </svg>
                      </div>
                      <div
                        title="Delete file"
                        class="rounded-xl text-red-400 hover:text-white transition-all cursor-pointer w-8 h-8 bg-white hover:bg-red-400 opacity-100 hover:opacity-30 flex items-center justify-center"
                        @click="() => deleteUploadedFile(file.id)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          class="w-4 h-4"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </template>
                <!-- /Tab content -->
              </div>
              <!-- Active tab: NOT New Files -->
            </div>

            <!-- Selected files -->
            <div
              v-if="selectedFiles.length"
              ref="selectedFilesListParent"
              class="w-1/3 bg-white rounded-lg p-8 divide-y divide-truegray-100 mt-12 lg:mt-0 h-[max-content]"
            >
              <h5>Selected:</h5>
              <div
                v-for="file in selectedFiles"
                :key="file.id"
                class="flex items-center justify-between group"
              >
                <div class="flex items-center py-2">
                  <p class="font-Inter text-ces-blue m-0">
                    {{ file.title }}
                  </p>

                  <p
                    v-if="isNewFile(file.id)"
                    class="font-Inter text-ces-blue m-0 ml-2 italic"
                  >
                    (new)
                  </p>
                  <div
                    title="remove"
                    class="flex items-center justify-center text-red-400 hover:text-red-500 cursor-pointer ml-6"
                    @click="() => toggleSelectedFile(file.id)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                class="flex justify-center items-center rounded-full shadow-sm gap-x-2 px-6 py-2.5 text-base font-Inter font-bold border border-ces-blue w-min sm:text-sm text-white bg-ces-blue hover:bg-white hover:text-ces-blue mt-6"
                :class="
                  selectedFileIds.length > 0
                    ? 'opacity-100 cursor-pointer'
                    : 'opacity-60 cursor-not-allowed'
                "
                @click="onSubmitAttachFiles"
              >
                Attach
              </div>
            </div>
            <!-- /Selected files -->
          </div>
          <!-- /Tab content -->
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, reactive, computed, watch } from 'vue'
import { DialogTrigger } from 'radix-vue'
import { useStore } from 'vuex'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import { reset } from '@formkit/core'
import Modal from './Modal.vue'
import { pluraliseNoun } from '../../store/util.js'

export default defineComponent({
  name: 'ModalFileLibrary',

  components: {
    Modal,
    DialogTrigger,
  },

  props: {
    isBoardResourcesContext: {
      type: Boolean,
      default: false,
    },

    attachedFileIds: {
      type: Array,
      default: () => [],
    },
  },

  emits: ['update:selectedFiles'],

  setup(props, { emit }) {
    const store = useStore()

    // #region Animations config
    const [tabContentParent] = useAutoAnimate()
    const [tabContentMainParent] = useAutoAnimate()
    const [selectedFilesListParent] = useAutoAnimate()
    const [uploadFilesInputParent] = useAutoAnimate()
    const [uploadFilesListParent] = useAutoAnimate()
    const [meetingParent] = useAutoAnimate()
    // #endregion

    // #region Modal config/state
    const MODAL_CONFIG = {
      trigger: {
        text: '',
        as: 'a',
        classes: '',
      },

      title: {
        text: 'File Library',
        classes: 'hidden',
      },

      description: {
        text: 'Manage all of your board files to use throughout your portal',
        classes: 'hidden',
      },

      contentClasses:
        'w-[80vw] h-[80vh] bg-truegray-100 overflow-hidden modal-content',
    }

    const triggerText = computed(() => {
      if (props.isBoardResourcesContext) {
        return 'Add Resources'
      }

      return 'Open File Library'
    })

    const isModalOpen = ref(false)
    const updateIsModalOpen = (val) => {
      isModalOpen.value = val
    }
    // #endregion

    // #region Tabs
    const FILE_TABS = [
      {
        id: 'new',
        title: 'New Files',
      },
      {
        id: 'meeting',
        title: 'Meeting Files',
        getter: 'portal/getLibraryMeetingFiles',
        action: 'portal/dispatchGetLibraryMeetingFiles',
      },
      {
        id: 'resource',
        title: 'Board Resources',
        getter: 'portal/getLibraryResourceFiles',
        action: 'portal/dispatchGetLibraryResourceFiles',
      },
      {
        id: 'misc',
        title: 'Other',
        getter: 'portal/getLibraryMiscFiles',
        action: 'portal/dispatchGetLibraryMiscFiles',
      },
    ]
    const activeFileTab = ref(FILE_TABS[0])

    const isBoardResourcesTab = computed(
      () => activeFileTab.value?.id === 'resource',
    )

    const isActiveFileTab = (id) => {
      return activeFileTab.value.id === id
    }

    const setActiveFileTab = (id) => {
      activeFileTab.value = FILE_TABS.find((tab) => tab.id === id)
    }

    const selectFileTab = async (id) => {
      setActiveFileTab(id)
      await getFiles()
    }
    // #endregion

    // #region New Files tab (upload form)
    const uploadFilesForm = ref({})
    const isUploadingFiles = ref(false)
    const filesToUpload = computed(() => uploadFilesForm?.value?.filesToUpload)
    const canUploadFiles = computed(
      () => !isUploadingFiles.value && filesToUpload.value?.length,
    )
    const uploadFilesInputLabel = computed(() => {
      return props.isBoardResourcesContext ? 'Select files' : 'Attach files'
    })
    const uploadFilesSubmitLabel = computed(() => {
      if (props.isBoardResourcesContext) {
        return pluraliseNoun(
          'Upload board resource',
          uploadFilesForm?.value?.filesToUpload?.length || 1,
        )
      }

      return 'Upload and select'
    })

    const onSubmitUploadFiles = async (data) => {
      if (canUploadFiles.value) {
        isUploadingFiles.value = true

        const formData = new FormData()
        data.filesToUpload.forEach((file, index) => {
          formData.append(`files[${index}]`, file.file)
          formData.append(`names[${index}]`, file.name)
          formData.append(`publicExcluded[${index}]`, !!file.publicExcluded)
          formData.append(
            `isBoardResource[${index}]`,
            !!props.isBoardResourcesContext,
          )
        })

        const uploadedFileIds = await store.dispatch(
          'portal/dispatchUploadFiles',
          formData,
        )

        if (!props.isBoardResourcesContext) {
          selectedFileIds.value = [...selectedFileIds.value, ...uploadedFileIds]
          newFileIds.value = [...newFileIds.value, ...uploadedFileIds]
        }

        reset('uploadFilesForm')
        isUploadingFiles.value = false

        if (props.isBoardResourcesContext) {
          isModalOpen.value = false
        }
      }
    }
    // #endregion

    // #region Meeting Files tab
    const meetingTabSectionsExpanded = reactive({})

    const toggleMeetingTabSectionExpanded = (index) => {
      meetingTabSectionsExpanded[index] = !meetingTabSectionsExpanded[index]
    }

    const resetMeetingTabSectionsExpanded = () => {
      Object.keys(meetingTabSectionsExpanded).forEach((key) => {
        delete meetingTabSectionsExpanded[key]
      })
    }
    // #endregion

    // #region Files
    const files = computed(() => store.getters[activeFileTab.value.getter])
    const filteredFiles = computed(() => {
      const fileMatches = (file, search) =>
        file.title.toLowerCase().includes(search)

      if (search.value) {
        const searchLower = search.value.toLowerCase()

        if (activeFileTab.value?.id === 'meeting') {
          // @TODO Portal 2.0 - For meetings, files are actually an array of meetings with
          // files attached, so we have to search their nested arrays. We could definitely
          // improve/standardise data structure so we don't have to do stuff like this!
          return files.value
            .map((meeting) => {
              const matchingFiles = meeting.files.filter((file) =>
                file.title.toLowerCase().includes(searchLower),
              )

              return matchingFiles?.length
                ? { ...meeting, files: matchingFiles }
                : null
            })
            .filter((meeting) => !!meeting)
        } else {
          return files.value.filter((file) => fileMatches(file, searchLower))
        }
      }

      return files.value
    })

    const isModifyingUploadedFile = computed(
      () => isEditingUploadedFile.value || isDeletingUploadedFile.value,
    )

    const getFileLabelClass = (fileId) => {
      const base = '$reset font-Inter ml-3 text-sm'

      if (isUploadedFileBeingDeleted(fileId)) {
        return `${base} text-red-200 font-bold`
      } else if (isUploadedFileBeingEdited(fileId)) {
        return `${base} text-ces-teal font-bold`
      } else {
        return `${base} text-ces-blue`
      }
    }

    const getFiles = async ({
      page = 1,
      // filters = store.getters['connections/getFilterFormPayload'],
    } = {}) => {
      if (activeFileTab.value?.action) {
        await store.dispatch(activeFileTab.value.action, {
          page,
        })
      } else {
        await store.dispatch('portal/dispatchGetLibraryFiles')
      }
    }

    const onSubmitAttachFiles = () => {
      emit('update:selectedFiles', selectedFiles.value)
      isModalOpen.value = false
    }
    // #endregion

    // #region Editing file - pre-upload
    const editedFile = reactive({ index: null, file: null })
    const isEditingFile = computed(() => editedFile?.file)

    const resetEditedFile = () => {
      editedFile.index = null
      editedFile.file = null
    }

    const editFile = (index) => {
      editedFile.index = index
      editedFile.file = { ...uploadFilesForm.value.filesToUpload[index] }
    }

    const onSubmitEditFile = () => {
      uploadFilesForm.value.filesToUpload[editedFile.index].name =
        editedFile.file.name
      resetEditedFile()
    }
    // #endregion

    // #region Editing file - post-upload
    const editedUploadedFile = ref(null)
    const isEditingUploadedFile = computed(() => !!editedUploadedFile.value)

    const isUploadedFileBeingEdited = (fileId) => {
      return editedUploadedFile.value?.id === fileId
    }

    const resetEditedUploadedFile = () => {
      editedUploadedFile.value = null
    }

    const editUploadedFile = (fileId) => {
      const originalFile = files.value.find((file) => file.id === fileId)
      editedUploadedFile.value = {
        originalTitle: originalFile.title,
        ...originalFile,
        publicExcluded: !!originalFile.public_excluded,
      }
    }

    const onSubmitEditUploadedFile = async () => {
      const payload = {
        id: editedUploadedFile.value.id,
        publicExcluded: editedUploadedFile.value.publicExcluded,
        title: editedUploadedFile.value.title,
        libraryType: activeFileTab.value.id?.toUpperCase(),
      }

      const success = await store.dispatch('portal/dispatchEditFile', payload)
      if (success) {
        resetEditedUploadedFile()
      }
    }
    // #endregion

    // #region Deleting file - post-upload
    const deletedUploadedFileId = ref(null)
    const isDeletingUploadedFile = computed(() => !!deletedUploadedFile.value)

    const deletedUploadedFile = computed(() => {
      if (deletedUploadedFileId.value) {
        return files.value.find(
          (file) => file.id === deletedUploadedFileId.value,
        )
      }

      return null
    })

    const isUploadedFileBeingDeleted = (fileId) => {
      return deletedUploadedFileId.value === fileId
    }

    const resetDeleteUploadedFile = () => {
      deletedUploadedFileId.value = null
    }

    const deleteUploadedFile = (fileId) => {
      deletedUploadedFileId.value = fileId
    }

    const onSubmitDeleteUploadedFile = async () => {
      const payload = {
        id: deletedUploadedFileId.value,
        libraryType: activeFileTab.value.id?.toUpperCase(),
      }

      const success = await store.dispatch(
        'portal/dispatchDeleteUploadedFile',
        payload,
      )
      if (success) {
        resetDeleteUploadedFile()
      }
    }
    // #endregion

    // #region Search
    const search = ref(null)
    const canSearch = computed(() => {
      return !isActiveFileTab('new')
    })

    const resetSearch = () => {
      search.value = null
    }
    // #endregion

    // #region Selected files
    const selectedFileIds = ref([])
    const selectedFiles = computed(() => {
      return store.getters['portal/getLibraryFilesById'](selectedFileIds.value)
    })

    const toggleSelectedFile = (fileId) => {
      const index = selectedFileIds.value.indexOf(fileId)
      if (index === -1) {
        selectedFileIds.value.push(fileId)
      } else {
        selectedFileIds.value.splice(index, 1)
      }
    }

    const newFileIds = ref([])
    const isNewFile = (id) => {
      return newFileIds.value.includes(id)
    }
    const resetNewFileIds = () => {
      newFileIds.value = []
    }
    // #endregion

    // #region Init
    /**
     * init
     *
     * Reset everything! All forms and state.
     * Refresh files data.
     */
    const init = async () => {
      uploadFilesForm.value = {}
      activeFileTab.value = FILE_TABS[0]
      isUploadingFiles.value = false
      resetMeetingTabSectionsExpanded()
      resetEditedFile()
      resetEditedUploadedFile()
      resetDeleteUploadedFile()
      resetSearch()
      selectedFileIds.value = props.attachedFileIds
      resetNewFileIds()
      await getFiles()
    }

    watch(isModalOpen, async () => {
      await init()
    })
    // #endregion

    return {
      // Animations config
      tabContentParent,
      tabContentMainParent,
      selectedFilesListParent,
      uploadFilesInputParent,
      uploadFilesListParent,
      meetingParent,

      // Modal config/state
      MODAL_CONFIG,
      triggerText,
      isModalOpen,
      updateIsModalOpen,

      // Tabs
      FILE_TABS,
      activeFileTab,
      isBoardResourcesTab,
      isActiveFileTab,
      setActiveFileTab,
      selectFileTab,

      // New Files tab
      uploadFilesForm,
      isUploadingFiles,
      uploadFilesInputLabel,
      uploadFilesSubmitLabel,
      onSubmitUploadFiles,

      // Meeting Files tab
      meetingTabSectionsExpanded,
      toggleMeetingTabSectionExpanded,

      // Files
      files,
      filteredFiles,
      isModifyingUploadedFile,
      getFileLabelClass,
      onSubmitAttachFiles,

      // Editing file - pre-upload
      editedFile,
      isEditingFile,
      editFile,
      onSubmitEditFile,

      // Editing file - post-upload
      editedUploadedFile,
      isEditingUploadedFile,
      isUploadedFileBeingEdited,
      resetEditedUploadedFile,
      editUploadedFile,
      onSubmitEditUploadedFile,

      // Deleting file - post-upload
      deletedUploadedFile,
      isDeletingUploadedFile,
      isUploadedFileBeingDeleted,
      resetDeleteUploadedFile,
      deleteUploadedFile,
      onSubmitDeleteUploadedFile,

      // Search
      search,
      canSearch,

      // Selected files
      selectedFileIds,
      selectedFiles,
      toggleSelectedFile,
      newFileIds,
      isNewFile,
    }
  },
})
</script>

<style>
.modal-content {
  padding: 0 !important;
}

.group .peer li {
  display: none !important;
}
</style>
