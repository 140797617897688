<template>
  <main class="bg-gray-primary">
    <PageHeader title="Portal Help & Guides" />

    <PageContent>
      <template #default>
        <div class="w-full mb-24">
          <h5 class="mb-8">Walkthrough</h5>
          <video width="1000" class="w-full rounded-xl" controls>
            <source
              src="https://cessl-app.s3.ap-southeast-2.amazonaws.com/all-resources/CES+Portal+Walkthrough+Video.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        </div>
        <!--
          <div class="w-full">
          <h3 class="mb-8">Guides</h3>
          
          <div
          class="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 sm:mx-0 lg:max-w-none xl:grid-cols-3"
          >
          <Card v-for="(card, index) in cards" :key="index" :data="card" />
          </div> 
         
          </div> 
        -->
      </template>
    </PageContent>
  </main>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
import Card from '../../components/Card.vue'

export default defineComponent({
  name: 'PortalHelp',

  components: {
    Vue3Lottie,
    PageHeader,
    PageContent,
    Card,
  },

  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const isLoading = computed(() => store.getters['global/isLoading'])

    const route = useRoute()

    const cards = []

    return {
      ANIMATION_LOADING,
      isLoading,
      isAdmin,
      route,
      cards,
    }
  },
})
</script>
