import {
  signIn,
  signOut,
  getMeetings,
  getMeeting,
  getMeetingDefaultAttendees,
  getMeetingMissingAttendees,
  upsertMeeting,
  deleteMeeting,
  getReports,
  getOwners,
  getMembers,
  setMember,
  deleteMember,
  getLibraryMeetingFiles,
  getLibraryResourceFiles,
  getLibraryMiscFiles,
  uploadFiles,
  addFile,
  editFile,
  deleteFile,
  getSettings,
  updateGeneralSettings,
  updateMeetingLocationDefaults,
} from '../../api/portal.js'
import { triggerAlert } from '../util.js'

const handleError = (data = null) => {
  if (data?.context) {
    if (data.mutation) {
      data.context?.commit(data.mutation)
    }

    if (data.alert) {
      triggerAlert(data.context, { data })
    }
  }

  // @todo trigger error messaging
  return false
}

export async function dispatchSignIn(context, payload = null) {
  try {
    if (payload) {
      const response = await signIn(payload)
      const data = response.data
      if (data?.success) {
        auth.signIn(data.access_token, data.user, data.expires)
      } else {
        handleError({
          context,
          mutation: 'CLEAR_SCHOOLS',
        })
      }

      return data
    }

    return handleError({
      context,
      mutation: 'CLEAR_SCHOOLS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_SCHOOLS',
    })
  }
}

export async function dispatchSignOut() {
  try {
    const response = await signOut()
    const data = response.data
    auth.signOut()

    if (data?.success) {
      return true
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export function dispatchSetSchools(context, schools) {
  try {
    if (schools) {
      context.commit('UPDATE_SCHOOLS', schools)
    } else {
      context.commit('CLEAR_SCHOOLS')
    }
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_SCHOOLS',
    })
  }
}

export function dispatchSetSchool(context, school) {
  try {
    if (school) {
      context.commit('UPDATE_SCHOOL', school)
      // Settings are also attached to the school object itself, but there
      // were issues keeping in sync after any user updates to settings
      // (logo, default locations). To fix that, we'd have to find and update
      // school.settings in the store, because this (dispatchSetSchool) seems
      // to trump any dispatchGetSettings called after those updates.
      // We can't dispatchGetSettings from here either, as dispatchSetSchool is called
      // from the beforeEnter hook - makes the app "lag" every time you click a new page!
      // Most reliable to re-get settings completely on route changes (watcher in Portal.vue).
      // Then we can deal with global isLoading states at the component level.
    } else {
      context.commit('CLEAR_SCHOOL')
    }
  } catch {
    return handleError()
  }
}

export async function dispatchGetMeetings(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getMeetings(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_MEETINGS', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_MEETINGS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_MEETINGS',
    })
  }
}

export async function dispatchGetMeeting(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getMeeting(clientId, payload.meetingId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_CURRENT_MEETING', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_CURRENT_MEETING',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_CURRENT_MEETING',
    })
  }
}

export async function dispatchClearMeeting(context) {
  try {
    context.commit('CLEAR_CURRENT_MEETING')
    return true
  } catch {
    return false
  }
}

export async function dispatchGetMeetingDefaultAttendees(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getMeetingDefaultAttendees(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_DEFAULT_ATTENDEES', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_DEFAULT_ATTENDEES',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_DEFAULT_ATTENDEES',
    })
  }
}

export async function dispatchGetMeetingMissingAttendees(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getMeetingMissingAttendees(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        return data
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchUpsertMeeting(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await upsertMeeting(
        clientId,
        payload.id || null,
        payload,
      )

      triggerAlert(context, response)
      if (response.data?.success) {
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchDeleteMeeting(context, id = null) {
  try {
    if (id) {
      const clientId = context.getters.getClientId
      const response = await deleteMeeting(clientId, id)
      const data = response.data

      if (data?.success) {
        triggerAlert(context, response)
        await context.dispatch('dispatchGetMeetings')
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchGetReports(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getReports(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_REPORTS', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_REPORTS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_REPORTS',
    })
  }
}

export async function dispatchGetOwners(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getOwners(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_OWNERS', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_OWNERS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_OWNERS',
    })
  }
}

export async function dispatchGetMembers(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getMembers(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_MEMBERS', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_MEMBERS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_MEMBERS',
    })
  }
}

export async function dispatchSetMember(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      payload.client_id = clientId
      const response = await setMember(clientId, payload)

      triggerAlert(context, response)
      if (response.data?.success) {
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchDeleteMember(context, id) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await deleteMember(clientId, id)

      triggerAlert(context, response)
      if (response.data?.success) {
        await context.dispatch('dispatchGetMembers')
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchGetLibraryFiles(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      await Promise.all([
        context.dispatch('dispatchGetLibraryMeetingFiles'),
        context.dispatch('dispatchGetLibraryResourceFiles'),
        context.dispatch('dispatchGetLibraryMiscFiles'),
      ])

      return true
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchGetLibraryMeetingFiles(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getLibraryMeetingFiles(clientId, payload)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_LIBRARY_MEETING_FILES', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_LIBRARY_MEETING_FILES',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_LIBRARY_MEETING_FILES',
    })
  }
}

export async function dispatchGetLibraryResourceFiles(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getLibraryResourceFiles(clientId, payload)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_LIBRARY_RESOURCE_FILES', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_LIBRARY_RESOURCE_FILES',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_LIBRARY_RESOURCE_FILES',
    })
  }
}

export async function dispatchGetLibraryMiscFiles(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getLibraryMiscFiles(clientId, payload)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_LIBRARY_MISC_FILES', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_LIBRARY_MISC_FILES',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_LIBRARY_MISC_FILES',
    })
  }
}

export async function dispatchUploadFiles(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await uploadFiles(clientId, payload)

      // @TODO Portal 2.0 implememt success message in response and trigger it!
      // triggerAlert(context, response)
      if (response.data?.success) {
        await context.dispatch('dispatchGetLibraryFiles')
        return response.data?.data
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchAddFile(context, payload) {
  try {
    const clientId = context.getters.getClientId
    payload.append('client_id', clientId)

    if (clientId) {
      const response = await addFile(clientId, payload)

      triggerAlert(context, response)
      if (response.data?.success) {
        await context.dispatch('dispatchGetReports')
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchEditFile(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await editFile(clientId, payload)

      if (response.data?.success) {
        const LIBRARY_TYPE = payload.libraryType
        const mutation = `UPDATE_LIBRARY_${LIBRARY_TYPE}_FILE`
        context.commit(mutation, response.data.data)

        // @TODO Portal 2.0 - proper alerts from backend response!
        triggerAlert(context, {
          data: {
            alert: {
              status: 'success',
              message: 'File updated.',
            },
          },
        })

        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchDeleteFile(context, payload) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      payload = {
        file_id: payload.id,
        ...payload,
      }
      const response = await deleteFile(clientId, payload)

      if (response.data?.success) {
        const LIBRARY_TYPE = payload.libraryType
        const mutation = `DELETE_LIBRARY_${LIBRARY_TYPE}_FILE`
        context.commit(mutation, payload.id)
        triggerAlert(context, response)
        return true
      }
    }

    // @TODO Portal 2.0 - proper alerts from backend response!
    return handleError({
      context,
      alert: {
        status: 'error',
        message: 'Failed to delete file.',
      },
    })
  } catch {
    return handleError({
      context,
      alert: {
        status: 'error',
        message: 'Failed to delete file.',
      },
    })
  }
}

export async function dispatchGetSettings(context) {
  try {
    const clientId = context.getters.getClientId

    if (clientId) {
      const response = await getSettings(clientId)
      const data = response.data?.data

      if (response.data?.success) {
        context.commit('UPDATE_SETTINGS', data)
        return true
      }
    }

    return handleError({
      context,
      mutation: 'CLEAR_SETTINGS',
    })
  } catch {
    return handleError({
      context,
      mutation: 'CLEAR_SETTINGS',
    })
  }
}

export async function dispatchUpdateGeneralSettings(context, payload) {
  try {
    const clientId = context.getters.getClientId
    payload.append('client_id', clientId)
    if (clientId) {
      // Clear the logo to force re-render because the file name doesn't change
      // so Vue doesn't detect the update!
      context.commit('IS_UPDATING_LOGO', true)
      const response = await updateGeneralSettings(clientId, payload)

      triggerAlert(context, response)
      if (response.data?.success) {
        await context.dispatch('dispatchGetSettings')
        context.commit('IS_UPDATING_LOGO', false)
        return true
      }
    }

    context.commit('IS_UPDATING_LOGO', false)
    return handleError()
  } catch {
    return handleError()
  }
}

export async function dispatchUpdateMeetingLocationDefaults(context, payload) {
  try {
    const clientId = context.getters.getClientId
    const data = {
      locations: payload,
      client_id: clientId,
    }
    if (clientId) {
      const response = await updateMeetingLocationDefaults(clientId, data)

      triggerAlert(context, response)
      if (response.data?.success) {
        await context.dispatch('dispatchGetSettings')
        return true
      }
    }

    return handleError()
  } catch {
    return handleError()
  }
}
