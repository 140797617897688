<template>
  <div
    class="absolute top-0 z-50"
    :style="{ left: '-3rem' }"
    :class="isFullWidth ? 'w-full' : 'w-auto'"
  >
    <div
      ref="drawersParent"
      :class="['flex flex-row h-full rounded-r-xl overflow-hidden shadow']"
    >
      <!-- Primary drawer -->
      <slot name="primary-drawer">
        <div
          :class="[
            'flex flex-col gap-10 py-16 bg-gray-50 rounded-sm transition-all duration-300 relative',
            !isLoading
              ? isPrimaryDrawerCollapsed
                ? 'w-16 px-3 text-center'
                : isSecondaryDrawerVisible
                ? 'w-60 px-8'
                : 'w-full px-16'
              : 'w-0',
          ]"
        >
          <!-- Template required for transitions -->
          <template v-if="!isLoading">
            <!-- Primary drawer collapse toggle -->
            <div
              v-if="isPrimaryDrawerCollapsed"
              :class="[
                'absolute cursor-pointer',
                isPrimaryDrawerCollapsed
                  ? 'top-0 right-0 w-full h-12 flex items-center justify-center hover:bg-ces-blue hover:text-white rounded-tr-xl overflow-hidden'
                  : 'top-4 right-4',
              ]"
              @click="toggleIsPrimaryDrawerCollapsed"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                :class="[
                  'transition-all',
                  isPrimaryDrawerCollapsed ? 'w-5 h-5' : 'w-5 h-5 rotate-180',
                ]"
              >
                <path
                  fill-rule="evenodd"
                  d="M15.28 9.47a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 1 1-1.06-1.06L13.69 10 9.97 6.28a.75.75 0 0 1 1.06-1.06l4.25 4.25ZM6.03 5.22l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L8.69 10 4.97 6.28a.75.75 0 0 1 1.06-1.06Z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <!-- /Primary drawer collapse toggle -->

            <!-- Primary drawer title -->
            <div
              v-if="!isPrimaryDrawerCollapsed && hasItems"
              :class="[
                'pb-2 px-1',
                { 'border-b border-gray-300': !isPrimaryDrawerCollapsed },
              ]"
            >
              <h5 :class="['transition-all relative']">
                {{ primaryDrawerTitle }}
              </h5>
            </div>
            <!-- /Primary drawer title -->

            <!-- Primary drawer items -->
            <div
              v-if="hasItems"
              class="flex w-full transition-all"
              :class="isPrimaryDrawerCollapsed ? 'opacity-0' : 'opacity-100'"
            >
              <!-- @TODO - Portal 2.0 - scroll area -->
              <!--
                <ScrollAreaRoot
                class="w-full h-[100px] overflow-hidden"
                style="--scrollbar-size: 10px"
                > 
              -->
              <!-- <ScrollAreaViewport class="w-full h-full"> -->
              <ul class="flex flex-col gap-4 w-full h-full">
                <li
                  v-for="primaryItem in primaryDrawerItems"
                  :key="primaryItem.value"
                  class="w-full"
                  :class="{ hidden: primaryItem.hidden }"
                  @click="() => setActivePrimaryDrawerItem(primaryItem.value)"
                >
                  <!-- Primary drawer item slot -->
                  <slot
                    name="primary-item"
                    :item="primaryItem"
                    :is-primary-drawer-collapsed="isPrimaryDrawerCollapsed"
                    :is-active="isActivePrimaryDrawerItem(primaryItem.value)"
                  >
                    <div
                      :class="[
                        'flex flex-row justify-between items-center text-ces-blue hover:text-ces-teal flex-1 w-full cursor-pointer transition-all duration-300',
                        isPrimaryDrawerCollapsed
                          ? 'text-xs py-1'
                          : 'text-base py-2 border-b border-gray-300',
                        {
                          'text-ces-teal': isActivePrimaryDrawerItem(
                            primaryItem.value,
                          ),
                        },
                      ]"
                      :title="primaryItem.title"
                    >
                      <div
                        :class="[
                          'flex flex-row items-center gap-3 w-full',
                          { 'justify-center': isPrimaryDrawerCollapsed },
                        ]"
                      >
                        <span
                          v-if="
                            primaryItem.iconLeft && !isPrimaryDrawerCollapsed
                          "
                          v-html="primaryItem.iconLeft"
                        />
                        <span class="truncate"> {{ primaryItem.title }}</span>
                      </div>

                      <span
                        v-if="
                          primaryItem.iconRight && !isPrimaryDrawerCollapsed
                        "
                        :class="{
                          'rotate-90': !isActivePrimaryDrawerItem(
                            primaryItem.value,
                          ),
                        }"
                        v-html="primaryItem.iconRight"
                      />
                    </div>
                  </slot>
                  <!-- /Primary drawer item slot -->
                </li>
              </ul>
              <!-- </ScrollAreaViewport> -->
              <!-- </ScrollAreaRoot> -->
            </div>
            <!-- /Primary drawer items -->

            <span v-else-if="noItemsText" class="text-ces-blue text-sm">
              {{ noItemsText }}
            </span>

            <div v-if="!isPrimaryDrawerCollapsed" class="flex w-full">
              <slot
                name="primary-action"
                :is-primary-drawer-collapsed="isPrimaryDrawerCollapsed"
              />
            </div>

            <!-- Collapsed icon/text -->
            <div v-if="isPrimaryDrawerCollapsed" class="pb-2 px-1">
              <div
                class="mb-4 flex items-center justify-center"
                v-html="primaryDrawerIcon"
              />
              <div
                class="transform rotate-90 ml-6 origin-top-left w-[max-content] relative left-[0.15rem] text-sm tracking-wider"
              >
                {{ primaryDrawerTitleCollapsed || primaryDrawerTitle }}
              </div>
            </div>
          </template>
        </div>
      </slot>

      <!-- /Primary drawer -->

      <!-- Secondary drawer -->
      <slot v-if="hasSecondaryDrawer" name="secondary-drawer">
        <div
          v-if="isSecondaryDrawerVisible"
          :key="activePrimaryDrawerItemValue"
          :class="[
            'bg-white pb-12 pt-32 shadow-inner rounded-sm relative transition-all duration-300 w-60 px-12',
          ]"
        >
          <!-- Secondary drawer items -->
          <div class="flex w-full">
            <ul
              :class="[
                'flex flex-col gap-4 w-full',
                isPrimaryDrawerCollapsed ? 'mt-1' : 'mt-3',
              ]"
            >
              <li
                v-for="secondaryItem in secondaryDrawerItems"
                :key="secondaryItem"
                @click="() => setActiveSecondaryDrawerItem(secondaryItem.value)"
              >
                <!-- Secondary drawer item slot -->
                <slot
                  name="secondary-item"
                  :item="secondaryItem"
                  :is-active="isActiveSecondaryDrawerItem(secondaryItem.value)"
                >
                  <div
                    :class="[
                      'flex flex-row items-center text-ces-blue hover:text-ces-teal flex-1 w-full cursor-pointer transition-all duration-300 font-semibold justify-between text-base py-2 border-b border-gray-300',
                      {
                        'text-ces-teal': isActiveSecondaryDrawerItem(
                          secondaryItem.value,
                        ),
                      },
                    ]"
                    :title="secondaryItem.title"
                  >
                    <span class="truncate">
                      {{ secondaryItem.title }}
                    </span>
                  </div>
                </slot>
                <!-- /Secondary drawer item slot -->
              </li>
            </ul>
          </div>

          <!-- /Secondary drawer items -->
        </div>
      </slot>
      <!-- Secondary drawer -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
// import {
//   ScrollAreaRoot,
//   ScrollAreaScrollbar,
//   ScrollAreaThumb,
//   ScrollAreaViewport,
// } from 'radix-vue'

export default defineComponent({
  name: 'PageDrawer',

  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },

    primaryDrawerTitle: {
      type: String,
      default: '',
    },

    primaryDrawerTitleCollapsed: {
      type: String,
      default: '',
    },

    primaryDrawerIcon: {
      type: String,
      default: '',
    },

    // Array of primary drawer item objects, each with a title,
    // value (unique id), icon left/right (not required), and array of
    // secondary items (if item should render secondary drawer - not required).
    primaryDrawerItems: {
      type: Array,
      required: true,
    },

    noItemsText: {
      type: String,
      default: '',
    },
  },

  emits: [
    // Sets active item (secondary item, or primary item if no secondary items exist).
    'update:activeItemValue',
  ],

  setup(props, { emit }) {
    const route = useRoute()
    const [drawersParent] = useAutoAnimate()
    const [titleParent] = useAutoAnimate()

    const hasItems = computed(() => !!props.primaryDrawerItems?.length)

    /******************* Primary drawer *******************/
    // Active item
    const activePrimaryDrawerItemValue = ref(null)

    const activePrimaryDrawerItem = computed(() =>
      props.primaryDrawerItems.find(
        (item) => item.value === activePrimaryDrawerItemValue.value,
      ),
    )

    const isActivePrimaryDrawerItem = (value) => {
      return activePrimaryDrawerItemValue.value === value
    }

    const setActivePrimaryDrawerItem = (value) => {
      // Primary drawer config
      activePrimaryDrawerItemValue.value = value
      isPrimaryDrawerCollapsed.value = true

      // Secondary drawer config
      isSecondaryDrawerVisible.value = true

      // Emit primary value to parent; parent listener should handle value/id selection
      emit('update:activeItemValue', {
        primary: true,
        value,
      })
    }

    // Visibility
    // Refreshing on a child route should collapse the drawers to begin with!
    // @TODO Portal 2.0 - Proper routing or pass the state from the parent for parseInt(route.query?.meeting),
    // as this does not handle the case that the meeting id is not found (see PortalBoardMeetings.vue logic/comments).
    const isPrimaryDrawerCollapsed = ref(
      !!route.meta.isChild || !!parseInt(route.query?.meeting) || false,
    )
    const isFullWidth = computed(() => {
      return !isSecondaryDrawerVisible.value && !isPrimaryDrawerCollapsed.value
    })

    const toggleIsPrimaryDrawerCollapsed = () => {
      isPrimaryDrawerCollapsed.value = !isPrimaryDrawerCollapsed.value
    }

    /******************* Secondary drawer *******************/
    // Items
    const hasSecondaryDrawer = computed(() => secondaryDrawerItems.value)

    const secondaryDrawerItems = computed(() => {
      return activePrimaryDrawerItem.value?.secondaryItems || null
    })

    // Active item
    const activeSecondaryDrawerItemValue = ref(null)

    const isActiveSecondaryDrawerItem = (value) => {
      return activeSecondaryDrawerItemValue.value === value
    }

    const setActiveSecondaryDrawerItem = (value) => {
      // Primary drawer config
      isPrimaryDrawerCollapsed.value = true

      // Secondary drawer config
      activeSecondaryDrawerItemValue.value = value
      isSecondaryDrawerVisible.value = false

      // Emit secondary value to parent; parent listener should handle value/id selection
      emit('update:activeItemValue', { secondary: true, value })
    }

    watch(isPrimaryDrawerCollapsed, (newVal) => {
      if (!newVal && activePrimaryDrawerItemValue.value) {
        isSecondaryDrawerVisible.value = true
      }
    })

    // Visibility
    const isSecondaryDrawerVisible = ref(false)

    return {
      // Autoanimate
      drawersParent,
      titleParent,
      hasItems,

      // Primary drawer - active item
      isFullWidth,
      activePrimaryDrawerItemValue,
      isActivePrimaryDrawerItem,
      setActivePrimaryDrawerItem,

      // Primary drawer - visibility
      isPrimaryDrawerCollapsed,
      toggleIsPrimaryDrawerCollapsed,

      // Secondary drawer - items
      secondaryDrawerItems,
      isActiveSecondaryDrawerItem,
      setActiveSecondaryDrawerItem,

      // Secondary drawer - visiblity
      hasSecondaryDrawer,
      isSecondaryDrawerVisible,
    }
  },
})
</script>
