<template>
  <div
    class="brand__card relative justify-between bg-white group hover:shadow-xl overflow-hidden p-0"
  >
    <a
      v-if="data.href"
      :href="data.href"
      target="_blank"
      rel="noopener noreferrer"
      class="absolute w-full h-full z-90"
    />

    <img
      :src="data.image"
      alt="Article image"
      class="w-full h-48 object-cover"
    />

    <div
      class="absolute top-32 left-0 flex justify-center w-max py-3 px-5 bg-tertiary text-white font-semibold text-sm shadow-inner"
    >
      {{ data.heading }}
    </div>

    <div class="flex flex-col justify-between gap-6 w-full p-8 grow">
      <div class="flex flex-col items-start gap-2 w-full">
        <h5 class="text-sm text-wrap mb-0">
          {{ data.title }}
        </h5>
        <span v-if="data.date" class="text-xs">{{ data.date }}</span>
      </div>

      <a class="flex flex-row items-center gap-1">
        <span class="text-xs">Read more</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-3 h-3"
        >
          <path
            fill-rule="evenodd"
            d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { data } from 'autoprefixer'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',

  props: {
    data: { type: Object, required: true },
  },
})
</script>
