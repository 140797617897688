<template>
  <main class="bg-lightgrey">
    <PageHeader title="Portal Resources"> </PageHeader>

    <PageContent>
      <template #default>
        <div class="w-full mb-24">
          <h3 class="mb-8">Walkthrough</h3>
          <video width="1000" class="w-full rounded-xl" controls>
            <source
              src="https://cessl-app.s3.ap-southeast-2.amazonaws.com/all-resources/CES+Portal+Walkthrough+Video.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML video.
          </video>
        </div>
        <div class="w-full">
          <h3 class="mb-8">Articles</h3>
          <div
            class="mx-auto grid grid-cols-1 sm:grid-cols-2 gap-6 sm:mx-0 lg:max-w-none xl:grid-cols-3"
          >
            <Card v-for="(card, index) in cards" :key="index" :data="card" />
          </div>
        </div>
      </template>
    </PageContent>
  </main>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'
import { DRAWER_SVG_PRIMARY } from '../../constants'
import PageHeader from '../../components/PageHeader.vue'
import PageContent from '../../components/PageContent.vue'
import Card from '../../components/Card.vue'

export default defineComponent({
  components: {
    Vue3Lottie,
    PageHeader,
    PageContent,
    Card,
  },

  setup() {
    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    const isLoading = computed(() => store.getters['global/isLoading'])

    const route = useRoute()

    const cards = [
      {
        title: 'Board Culture',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/Dne48vc',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'What is Strategic Planning in Education?',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/GfXjgK4',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'Te Whakangārahu Ngātahi | Planning Together',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/xE86JFY',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'What Skills Do Board Members Need?',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/ENIbHFC',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'Why Join a School Board?',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/q124e9L',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
      {
        title: 'What Are Board Minutes?',
        heading: 'Article',
        href: 'https://hail.to/canterbury-education-services/article/zuJipOM',
        // description:
        //   'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
        date: 'February 9, 2024',
      },
    ]

    return {
      ANIMATION_LOADING,
      isLoading,
      isAdmin,
      route,
      cards,
    }
  },
})
</script>
