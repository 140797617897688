<template>
  <Modal
    :trigger="MODAL_CONFIG.trigger"
    :title="MODAL_CONFIG.title"
    :description="MODAL_CONFIG.description"
    :content-classes="MODAL_CONFIG.contentClasses"
    :is-dialog-open="isModalOpen"
    @update-modal-state="updateIsModalOpen"
  >
    <template #trigger>
      <DialogTrigger>
        <div class="flex items-center text-xs">
          <button
            type="button"
            class="rounded-full mr-4 p-2 bg-ces-blue hover:bg-ces-teal text-white flex items-center justify-center w-8 h-8 text-xs"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-3 h-3"
            >
              <path
                d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z"
              />
            </svg>
          </button>
          Add Missing Members
          <div class="group ml-4 relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              class="w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-6 3.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM7.293 5.293a1 1 0 1 1 .99 1.667c-.459.134-1.033.566-1.033 1.29v.25a.75.75 0 1 0 1.5 0v-.115a2.5 2.5 0 1 0-2.518-4.153.75.75 0 1 0 1.061 1.06Z"
                clip-rule="evenodd"
              />
            </svg>
            <div
              class="absolute w-[300px] z-50 right-0 bottom-0 opacity-0 pointer-events-none group-hover:opacity-100 bg-white rounded-lg border border-gray-400 p-3 transition-all"
            >
              <p class="text-xs text-gray-400 mb-0">
                Missing members are members who may have not have been
                automatically added to your meeting on creation. Reasons for
                this could include the member being added after the meeting was
                created, or wanting to add a deleted member retroactively to an
                older meeting.
              </p>
            </div>
          </div>
        </div>
      </DialogTrigger>
    </template>
    <template #content>
      <div class="pb-12 w-full bg-truegray-100 h-full">
        <div
          class="flex items-center justify-between bg-white pt-12 px-12 pb-4"
        >
          <h2>Add Missing Members</h2>
        </div>

        <div class="py-12 mx-12">
          <div
            class="flex flex-col bg-white rounded-lg overflow-hidden overflow-y-scroll max-h-[450px] mb-6 p-8"
          >
            <div
              v-if="!isLoading && missingMembers.length"
              ref="memberParent"
              class="flex flex-col divide-y divide-y-truegray-300"
            >
              <div
                v-for="(member, index) in missingMembers"
                :key="index"
                class="flex items-center justify-between group"
              >
                <div class="flex items-center">
                  <FormKit
                    :key="member.id"
                    type="checkbox"
                    :value="selectedMembers.some((m) => m.id === member.id)"
                    decorator-icon="check"
                    outer-class="$reset mb-0 pl-1"
                    @input="() => toggleSelectedMembers(member)"
                  />
                  <div class="flex flex-col mt-2 mb-2 ml-4">
                    <p class="font-Inter text-ces-blue mb-0">
                      {{ member.name }}
                    </p>
                    <h6 v-if="member.board_role" class="text-gray-400">
                      {{ member.board_role }}
                    </h6>
                    <h6 v-if="member.deleted_at" class="text-red-400">
                      Member removed on {{ member.deleted_at }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isLoading && !missingMembers.length" class="">
              <p class="text-gray-400">No missing members found...</p>
              <p class="text-gray-400">
                Missing members are members who may have not have been
                automatically added to your meeting on creation. Reasons for
                this could include the member being added after the meeting was
                created, or wanting to add a deleted member retroactively to an
                older meeting.
              </p>
            </div>
            <div class="mt-12">
              <button
                type="button"
                class="flex justify-center items-center rounded-full shadow-sm gap-x-2 px-6 py-2.5 text-base font-Inter font-bold border border-ces-blue w-min sm:text-sm text-white bg-ces-blue hover:bg-white hover:text-ces-blue mt-6"
                :class="
                  canSubmit
                    ? 'opacity-100 cursor-pointer'
                    : 'opacity-60 cursor-not-allowed'
                "
                :disabled="!canSubmit"
                @click="onSubmit"
              >
                Attach
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { DialogTrigger } from 'radix-vue'
import { useAutoAnimate } from '@formkit/auto-animate/vue'
import Modal from './Modal.vue'

export default defineComponent({
  name: 'ModalAddMissingMembers',

  components: {
    Modal,
    DialogTrigger,
  },

  props: {
    existingMembers: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { emit }) {
    const store = useStore()
    const [memberParent] = useAutoAnimate()

    const MODAL_CONFIG = {
      trigger: {
        text: '',
        as: 'a',
        classes: '',
      },

      title: {
        text: 'Missing Members',
        classes: 'hidden',
      },

      description: {
        text: 'Add previous members, or members added after the meeting creation',
        classes: 'hidden',
      },

      contentClasses:
        'w-[80vw] h-[80vh] max-w-[500px] bg-truegray-100 overflow-hidden px-0 pb-0',
    }

    const isLoading = ref(false)

    const missingMembers = ref([])

    const updateIsModalOpen = async (val) => {
      if (val) {
        isLoading.value = true
        const members = await store.dispatch(
          'portal/dispatchGetMeetingMissingAttendees',
        )
        const uniqueMembers = members.filter(
          (member) =>
            !props.existingMembers.some(
              (existingMember) => existingMember.value === member.id,
            ),
        )
        missingMembers.value = uniqueMembers
        isLoading.value = false
      }
    }

    const selectedMembers = ref([])

    const toggleSelectedMembers = (member) => {
      const index = selectedMembers.value.findIndex((m) => m.id === member.id)
      if (index !== -1) {
        selectedMembers.value.splice(index, 1)
      } else {
        selectedMembers.value.push(member)
      }
    }

    const isModalOpen = ref(true)

    const canSubmit = computed(() => !!selectedMembers.value.length)

    const onSubmit = () => {
      if (canSubmit.value) {
        emit('add-missing-members', selectedMembers.value)
        isModalOpen.value = false
      }
    }

    return {
      MODAL_CONFIG,
      isLoading,
      memberParent,
      updateIsModalOpen,
      isModalOpen,
      missingMembers,
      selectedMembers,
      toggleSelectedMembers,
      canSubmit,
      onSubmit,
    }
  },
})
</script>
