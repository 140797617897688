<template>
  <div class="flex w-full h-full bg-white rounded-xl overflow-hidden">
    <!-- Col 1 -->
    <div class="w-full lg:w-5/12 p-12 pr-8 bg-primary">
      <!-- Title row -->
      <div class="flex justify-between items-center w-full">
        <h3 class="text-white mb-12 pb-3 border-b border-white">
          {{ data.title }}
        </h3>
      </div>
      <!-- /Title row -->

      <!-- Details rows -->
      <div class="flex flex-col w-full gap-4">
        <!-- Date -->
        <div v-if="data.date" class="flex flex-row items-center gap-3 w-full">
          <svg-icon
            class="text-white flex-shrink-0"
            size="20"
            icon-name="calendar"
          />
          <span class="text-white text-xs font-bold">
            {{ data.date }}
          </span>
        </div>
        <!-- /Date -->

        <!-- Time -->
        <div v-if="data.time" class="flex flex-row items-center gap-3 w-full">
          <svg-icon
            class="text-white flex-shrink-0"
            size="20"
            icon-name="time"
          />
          <span class="text-white text-xs font-bold">
            {{ data.time }}
          </span>
        </div>
        <!-- /Time -->

        <!-- Location -->
        <div
          v-if="data.location"
          class="flex flex-row items-start gap-3 w-full"
        >
          <svg-icon
            class="text-white flex-shrink-0"
            size="20"
            icon-name="location"
          />
          <div class="flex flex-col">
            <span class="text-white text-xs font-bold">
              {{ data.location }}
            </span>
            <span v-if="data.address" class="text-white text-xs italic">
              {{ data.address }}
            </span>
            <a
              v-if="data.onlineLink"
              :href="data.onlineLink"
              target="_blank"
              rel="noopener noreferrer"
              class="brand__link--tertiary font-light"
            >
              {{ data.onlineLink }}
            </a>
          </div>
        </div>
        <!-- /Location -->
      </div>
    </div>
    <!-- Col 2 -->
    <div class="w-full lg:w-7/12 p-8">
      <div class="mb-0 flex w-full items-center justify-end">
        <button
          v-if="primaryData?.title && isAdmin"
          type="button"
          class="brand__button bg-red-400 border border-red-400 text-white hover:bg-white hover:text-red-400 px-3 transition-all"
          title="Delete meeting"
          @click="deleteMeeting"
        >
          <span>Delete</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-3 h-3"
          >
            <path
              fill-rule="evenodd"
              d="M8.75 1A2.75 2.75 0 0 0 6 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 1 0 .23 1.482l.149-.022.841 10.518A2.75 2.75 0 0 0 7.596 19h4.807a2.75 2.75 0 0 0 2.742-2.53l.841-10.52.149.023a.75.75 0 0 0 .23-1.482A41.03 41.03 0 0 0 14 4.193V3.75A2.75 2.75 0 0 0 11.25 1h-2.5ZM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4ZM8.58 7.72a.75.75 0 0 0-1.5.06l.3 7.5a.75.75 0 1 0 1.5-.06l-.3-7.5Zm4.34.06a.75.75 0 1 0-1.5-.06l-.3 7.5a.75.75 0 1 0 1.5.06l.3-7.5Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <button
          v-if="primaryData?.title && isAdmin"
          type="button"
          class="brand__button--primary ml-6 px-3 transition-all"
          title="Edit meeting"
          @click="routeToUpsert"
        >
          <span>Edit</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-3 h-3"
          >
            <path
              d="m2.695 14.762-1.262 3.155a.5.5 0 0 0 .65.65l3.155-1.262a4 4 0 0 0 1.343-.886L17.5 5.501a2.121 2.121 0 0 0-3-3L3.58 13.419a4 4 0 0 0-.885 1.343Z"
            />
          </svg>
        </button>
      </div>

      <div class="w-full flex flex-col justify-stretch items-start gap-5">
        <!-- Attendees -->
        <h5 class="pb-2 border-b border-truegray-700">Attendees</h5>
        <div class="flex flex-row items-center gap-3 w-full">
          <AccordionRoot type="single" :collapsible="true" class="w-full">
            <AccordionItem
              class="overflow-hidden first:mt-0 first:rounded-t last:rounded-b focus-within:z-10"
              :value="1"
            >
              <AccordionHeader as="div" class="flex">
                <AccordionTrigger
                  class="accordion-trigger flex flex-row flex-1 items-center gap-3 h-12 cursor-pointer bg-white text-xs outline-none group"
                >
                  <svg-icon size="20" icon-name="user" />
                  <div class="flex flex-row flex-grow items-center">
                    <div class="flex items-start gap-1 mr-8 font-light text-xs">
                      <span>
                        {{ getAttendeesText(attendees, 'attendee') }},
                        {{ getAttendeesText(apologies, 'apology') }}
                      </span>
                    </div>
                    <svg-icon
                      size="16"
                      icon-name="arrow"
                      class="accordion-trigger-icon text-gray-400 transition-all duration-300 ease-in-out"
                    />
                  </div>
                </AccordionTrigger>
              </AccordionHeader>
              <AccordionContent
                class="data-[state=open]:animate-accordionSlideDown data-[state=closed]:animate-accordionSlideUp overflow-hidden text-xs text-gray-400 pl-7"
              >
                <div class="grid grid-cols-2 gap-3 px-1 border-t pt-4">
                  <MeetingMembersList
                    title="Attendees"
                    :members="attendees"
                    wrapper-class="col-span-1"
                  />

                  <MeetingMembersList
                    title="Apologies"
                    :members="apologies"
                    wrapper-class="col-span-1"
                  />
                </div>
              </AccordionContent>
            </AccordionItem>
          </AccordionRoot>
        </div>
        <!-- /Attendees -->
        <hr />

        <!-- Files -->
        <div v-if="data.files.length" class="">
          <h5 class="pb-2 border-b border-truegray-700">Attachments</h5>
          <div class="w-full mt-4 ml-3">
            <FilesList :files="data.files" />
          </div>
        </div>
        <!-- /Files -->
      </div>
      <!-- Details rows -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from 'radix-vue'
import FilesList from './FilesList.vue'
import MeetingMembersList from './MeetingMembersList.vue'
import { getAttendeesText } from '../store/util.js'

export default defineComponent({
  name: 'CardMeeting',

  components: {
    AccordionContent,
    AccordionHeader,
    AccordionItem,
    AccordionRoot,
    AccordionTrigger,
    FilesList,
    MeetingMembersList,
  },

  props: {
    data: { type: Object, required: true },
  },

  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])

    const primaryData = computed(() => props.data.primaryData)
    const meetingId = computed(() => props.data.value)

    const attendees = computed(() => {
      return props.data?.attendees?.filter((m) => m.attending) || []
    })
    const apologies = computed(() => {
      return props.data?.attendees?.filter((m) => !m.attending) || []
    })

    const deleteMeeting = async () => {
      const success = await store.dispatch(
        'portal/dispatchDeleteMeeting',
        meetingId.value,
      )
      if (success) {
        emit('meeting-deleted')
      }
    }

    const routeToUpsert = () => {
      router.push({
        name: 'boardMeeting',
        params: { id: route.params.id },
        query: {
          id: meetingId.value,
        },
      })
    }

    return {
      isAdmin,
      getAttendeesText,
      primaryData,
      attendees,
      apologies,
      routeToUpsert,
      deleteMeeting,
    }
  },
})
</script>
