<template>
  <main class="bg-lightgrey">
    <div>
      <div v-if="isLoading" class="mx-auto w-12 h-12">
        <Vue3Lottie :animation-data="ANIMATION_LOADING.DARK" />
      </div>
      <div v-else>
        <div class="w-full flex items-end justify-end mb-8">
          <div
            class="cursor-pointer flex justify-center items-center rounded-full shadow-sm gap-x-2 px-6 py-2.5 text-base font-Inter font-bold border border-ces-blue sm:w-auto sm:text-sm hover:text-white hover:bg-ces-blue"
            @click="emitRouteChange"
          >
            <svg-icon aria-hidden="true" size="16" icon-name="plus" />
            Add Member
          </div>
        </div>
        <div
          v-if="owners.length"
          class="mb-8 pb-8 border-b border-truegray-300"
        >
          <ul
            role="list"
            class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
          >
            <li
              v-for="owner in owners"
              :key="owner.id"
              class="col-span-1 divide-y divide-lightgray rounded-2xl bg-white shadow flex flex-col justify-between"
            >
              <div class="flex w-full px-6 py-5">
                <div
                  class="flex items-center justify-between space-x-6 p-2 w-full"
                >
                  <div class="flex-1 w-full">
                    <div
                      class="flex w-full space-x-3 items-end justify-between mb-2"
                    >
                      <h3
                        class="truncate text-lg font-bold mb-0 w-10/12"
                        :title="owner.name"
                      >
                        {{ owner.name }}
                      </h3>
                      <img
                        src="/img/logo_stacked-300x300.png"
                        alt="CES Representative Logo"
                        class="w-8 h-8"
                      />
                    </div>
                    <div class="mt-1 truncate text-xs text-ces-gray">
                      CES Representative
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="-mt-px flex divide-x divide-lightgray">
                  <div class="flex w-0 flex-1">
                    <a
                      class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 group hover:text-ces-teal"
                      @click="() => copyToClipboard(owner.email, 'Email')"
                    >
                      <svg-icon
                        aria-hidden="true"
                        size="16"
                        icon-name="email"
                      />
                      Email
                    </a>
                  </div>
                  <div class="-ml-px flex w-0 flex-1">
                    <a
                      class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 group hover:text-ces-teal"
                      @click="
                        () => copyToClipboard(owner.phone, 'Phone number')
                      "
                    >
                      <svg-icon
                        aria-hidden="true"
                        size="16"
                        icon-name="phone"
                      />
                      Call
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-if="!members.length">
          <p>No board members of {{ selectedSchoolName }}</p>
        </div>
        <ul
          v-else
          role="list"
          class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
        >
          <li
            v-for="member in members"
            :key="member.id"
            class="col-span-1 divide-y divide-lightgray rounded-2xl bg-white shadow flex flex-col justify-between"
          >
            <div class="flex w-full px-6 py-5">
              <div
                class="flex items-center justify-between space-x-6 px-2"
                :class="member.isAdmin ? 'w-full' : 'w-10/12'"
              >
                <div class="flex-1 w-full">
                  <div
                    class="flex w-full space-x-3 items-center justify-between"
                  >
                    <h3
                      class="truncate text-lg font-bold mb-0"
                      :class="!member.isAdmin ? 'w-full' : 'w-9/12'"
                      :title="member.name"
                    >
                      {{ member.name }}
                    </h3>
                    <span
                      v-if="member.isAdmin"
                      class="inline-flex flex-shrink-0 items-center rounded-full px-2 py-0.5 text-xs font-medium text-ces-teal ring-1 ring-inset ring-ces-teal"
                      >Admin
                    </span>
                  </div>
                  <span
                    v-if="member.boardRole"
                    class="mt-1 truncate text-xs text-ces-gray"
                  >
                    {{ member.boardRole }}
                  </span>
                </div>
              </div>
              <div v-if="canActionMember()" class="flex justify-end">
                <PopoverRoot>
                  <PopoverTrigger
                    class="rounded-full w-[25px] h-[25px] inline-flex items-center justify-center bg-white cursor-pointer border border-gray-300 hover:border-gray-400"
                    aria-label="Member actions"
                  >
                    <svg-icon
                      icon-name="navbar"
                      aria-hidden="true"
                      size="14"
                      class="flex text-gray-500 hover:text-gray-600"
                    />
                  </PopoverTrigger>
                  <PopoverPortal>
                    <PopoverContent
                      side="bottom"
                      :side-offset="0"
                      class="rounded-lg w-auto bg-white border border-gray-200 will-change-[transform,opacity] data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade"
                    >
                      <div class="flex flex-col">
                        <button
                          type="button"
                          class="flex justify-start hover:bg-lightblue hover:text-ces-blue px-6 py-3 font-Inter font-semibold border-b border-gray-100"
                          @click="() => editMember(member)"
                        >
                          Edit
                        </button>
                        <AlertDialogRoot>
                          <AlertDialogTrigger
                            class="flex justify-start rounded-b-lg hover:bg-red-300 hover:text-white px-6 py-3 font-Inter font-semibold"
                          >
                            Delete
                          </AlertDialogTrigger>
                          <AlertDialogPortal>
                            <AlertDialogOverlay
                              class="bg-gray-600 bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-80"
                            />
                            <AlertDialogContent
                              class="z-[100] text-[15px] data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none"
                            >
                              <AlertDialogTitle
                                class="text-ces-blue m-0 font-semibold"
                              >
                                <h4>Delete {{ member.name }}?</h4>
                              </AlertDialogTitle>
                              <AlertDialogDescription
                                class="text-sm my-6 leading-normal"
                              >
                                Board member {{ member.name }} will be removed
                                from your board and deleted. Please be sure
                                before proceeding!
                              </AlertDialogDescription>
                              <div class="flex justify-end gap-[25px]">
                                <AlertDialogCancel
                                  class="rounded-full gap-x-2 px-6 py-2 text-base font-Inter font-bold sm:text-sm leading-6 text-gray-900 hover:bg-ces-blue hover:text-white border border-transparent"
                                >
                                  Cancel
                                </AlertDialogCancel>
                                <AlertDialogAction
                                  class="rounded-full gap-x-2 px-5 py-2 text-base font-Inter font-bold sm:text-sm leading-6 bg-red-300 hover:bg-red-400 text-white border border-transparent"
                                  @click="() => deleteMember(member)"
                                >
                                  Yes, delete member
                                </AlertDialogAction>
                              </div>
                            </AlertDialogContent>
                          </AlertDialogPortal>
                        </AlertDialogRoot>
                      </div>
                      <PopoverArrow class="fill-gray-200" />
                    </PopoverContent>
                  </PopoverPortal>
                </PopoverRoot>
              </div>
            </div>
            <div>
              <div class="-mt-px flex divide-x divide-lightgray">
                <div class="flex w-0 flex-1">
                  <a
                    class="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900 group hover:text-ces-teal"
                    @click="() => copyToClipboard(member.email, 'Email')"
                  >
                    <svg-icon aria-hidden="true" size="16" icon-name="email" />
                    Email
                  </a>
                </div>
                <div class="-ml-px flex w-0 flex-1">
                  <a
                    class="relative inline-flex w-0 flex-1 items-center justify-center gap-x-2 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900 group hover:text-ces-teal"
                    @click="() => copyToClipboard(member.phone, 'Phone number')"
                  >
                    <svg-icon aria-hidden="true" size="16" icon-name="phone" />
                    Call
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
  AlertDialogTrigger,
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from 'radix-vue'
import { Vue3Lottie } from 'vue3-lottie'
import { ANIMATION_LOADING } from '../../constants.js'

export default defineComponent({
  name: 'PortalSettingsMyBoard',

  components: {
    Vue3Lottie,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogOverlay,
    AlertDialogPortal,
    AlertDialogRoot,
    AlertDialogTitle,
    AlertDialogTrigger,
    PopoverArrow,
    PopoverContent,
    PopoverPortal,
    PopoverRoot,
    PopoverTrigger,
  },

  emits: ['emit-route-change'],

  setup(props, { emit }) {
    const store = useStore()
    const isAdmin = computed(() => store.getters['auth/isAdmin'])
    // const isLoading = computed(() => store.getters['global/isLoading'])
    const isLoading = ref(false)

    const router = useRouter()
    const route = useRoute()

    const init = async () => {
      isLoading.value = true
      await store.dispatch('portal/dispatchGetMembers')
      await store.dispatch('portal/dispatchGetOwners')
      isLoading.value = false
    }

    const emitRouteChange = () => {
      emit('emit-route-change', {
        primary: true,
        value: 'addBoardMember',
      })
    }

    const selectedSchoolName = computed(
      () => store.getters['portal/getSchool']?.name,
    )

    const owners = computed(() => store.getters['portal/getOwnersMapped'])
    const members = computed(() => store.getters['portal/getMembersMapped'])

    const canActionMember = () => {
      return isAdmin.value
    }

    const editMember = (member) => {
      router.push({
        name: 'addBoardMember',
        params: { id: route.params.id },
        query: { member: member.id },
      })
    }

    const deleteMember = async (member) => {
      store.dispatch('global/dispatchUpdateIsLoading', true)
      await store.dispatch('portal/dispatchDeleteMember', member.id)
      store.dispatch('global/dispatchUpdateIsLoading')
    }

    const copyToClipboard = (item, type) => {
      navigator.clipboard?.writeText(item)
      store.dispatch('global/dispatchUpdateAlertToast', {
        status: 'success',
        message: `${type} copied to clipboard`,
      })
    }

    onMounted(() => {
      init()
    })

    return {
      ANIMATION_LOADING,
      isLoading,
      isAdmin,
      route,
      emitRouteChange,
      init,
      selectedSchoolName,
      owners,
      members,
      canActionMember,
      editMember,
      deleteMember,
      copyToClipboard,
    }
  },
})
</script>
