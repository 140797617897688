<template>
  <div :ref="editableRef" class="flex flex-col gap-3">
    <component
      :is="isEditable ? 'div' : 'a'"
      v-for="(file, index) in orderedFiles"
      :key="file.id"
      :href="!isEditable ? file.file : null"
      rel="noopener noreferrer"
      target="_blank"
      :class="['flex flex-row items-center gap-3 text-sm group']"
      :title="isEditable ? '' : 'Download file'"
    >
      <span v-if="isEditable">{{ index }}.</span>
      <div
        :class="[
          'flex flex-row items-center justify-between flex-grow border rounded-lg relative bg-white',
          {
            'cursor-grab active:cursor-grabbing hover:bg-gray-50': isEditable,
          },
          isBoardResourcesContext ? 'px-4 py-3' : 'px-3 py-2',
        ]"
      >
        <div :class="['flex items-center', isEditable ? 'gap-4' : 'gap-3']">
          <svg
            v-if="isEditable"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4"
          >
            <path
              fill-rule="evenodd"
              d="M2 3.75A.75.75 0 0 1 2.75 3h14.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75Zm0 4.167a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Zm0 4.166a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Zm0 4.167a.75.75 0 0 1 .75-.75h14.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            v-else-if="isBoardResourcesContext"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 group-hover:text-ces-teal"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4 group-hover:text-ces-teal"
          >
            <path
              fill-rule="evenodd"
              d="M15.621 4.379a3 3 0 0 0-4.242 0l-7 7a3 3 0 0 0 4.241 4.243h.001l.497-.5a.75.75 0 0 1 1.064 1.057l-.498.501-.002.002a4.5 4.5 0 0 1-6.364-6.364l7-7a4.5 4.5 0 0 1 6.368 6.36l-3.455 3.553A2.625 2.625 0 1 1 9.52 9.52l3.45-3.451a.75.75 0 1 1 1.061 1.06l-3.45 3.451a1.125 1.125 0 0 0 1.587 1.595l3.454-3.553a3 3 0 0 0 0-4.242Z"
              clip-rule="evenodd"
            />
          </svg>
          <span :class="[{ 'group-hover:text-ces-teal': !isEditable }]">
            {{ file.title }}
          </span>
        </div>

        <div v-if="file.public_excluded" title="Private file (public excluded)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-4 h-4 text-gray-400"
          >
            <path
              fill-rule="evenodd"
              d="M10 1a4.5 4.5 0 0 0-4.5 4.5V9H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-.5V5.5A4.5 4.5 0 0 0 10 1Zm3 8V5.5a3 3 0 1 0-6 0V9h6Z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch, nextTick } from 'vue'
import { useDragAndDrop } from '@formkit/drag-and-drop/vue'

export default defineComponent({
  name: 'FilesList',

  props: {
    files: { type: Array, default: () => [] },
    isEditable: { type: Boolean, default: false },
    isBoardResourcesContext: { type: Boolean, default: false },
    wrapperClass: { type: String, default: '' },
  },

  emits: ['update:selectedFiles'],

  setup(props, { emit }) {
    const [parentRef, orderedFiles] = useDragAndDrop(props.files)
    const editableRef = computed(() => (props.isEditable ? 'parentRef' : null))
    const isUpdating = ref(false)

    // isUpdating and nextTick are very important to
    // prevent recursion updating parent/child files data!
    // Otherwise these watchers will loop infinitely.
    watch(orderedFiles, async (files) => {
      if (!isUpdating.value) {
        isUpdating.value = true
        emit('update:selectedFiles', files)
        await nextTick()
        isUpdating.value = false
      }
    })

    watch(
      () => props.files,
      async () => {
        if (!isUpdating.value) {
          isUpdating.value = true
          orderedFiles.value = props.files
          await nextTick()
          isUpdating.value = false
        }
      },
    )

    return { editableRef, parentRef, orderedFiles }
  },
})
</script>
